/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from 'prop-types';
import React from 'react';

const ConversionLink = props => {
  return (
    <a
      {...props}
      onClick={e => {
        if (typeof props.onClick === `function`) {
          props.onClick(e);
        }
        let redirect = true;
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }
        if (props.target && props.target.toLowerCase() !== `_self`) {
          redirect = false;
        }
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            send_to: 'AW-666938962/LE_nCIDr88MBENLcgr4C',
            transport_type: redirect ? `beacon` : ``,
            event_callback() {
              if (redirect) {
                document.location = props.href;
              }
            },
          });
        } else if (redirect) {
          document.location = props.href;
        }

        return false;
      }}
    />
  );
};

ConversionLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

export default ConversionLink;
