import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import ConversionLink from '../Gtag/ConversionLink';

const Header = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={300} delay={100} distance="30px">
          <h1 className="hero-title">
            Fizyoterapist <br />
            <span className="text-color-main">Talha Bahtiyar</span>
            <br />
            <ConversionLink className="dark-blue-text hero-title" href="tel:+905442580900">
              <span className="dark-blue-text">0544 258 09 00</span>
            </ConversionLink>
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={300} delay={600} distance="30px">
          <ul className="location">
            <li>İzmir</li>
            <li>Manisa</li>
          </ul>
          <div className="tagline">Evde Fizik Tedavi</div>

          <div className="hero-cta">
            <a className="cta-btn cta-btn--hero" href="#aboutme">
              Detaylar & Randevu
            </a>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
