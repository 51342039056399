import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Talha Bahtiyar | Fizyoterapist | İzmir, Manisa | Fizik Tedavi', // e.g: 'Name | Developer'
  lang: 'tr', // e.g: en, es, fr, jp
  description:
    'Tedaviler, gerekli görüldüğünde Manuel Terapi, Mobilizasyon, Kinesiotaping (Bantlama), Elektroterapi, Masaj Teknikleri, Tetik Nokta Masajı, Cupping (Hacamat) gibi daha bir çok teknik ve Kişiye Özel Egzersiz Programı ile kombine olarak uygulanır. Nöroloji Hastalıklarda Fizyoterapi Parkinson İnme (Felç) Serebral Palsi (CP) Multiple Skleroz (MS) Brachial Pleksus Spina Bifida Denge Bozukluğu Myopati (Kas Hastalıkları) Polinöropati (Sinirlerin işlevlerini kaybetmesi) Sinir Yaralanmaları Omurilik Yaralanmaları Fasiyal Paralizi (Yüz Felci) Ve Sinir Sistemini Etkileyen Diğer Problemler…', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Fizyoterapist',
  name: 'Talha Bahtiyar',
  subtitle: 'Daha fazla..',
  cta: 'Detaylar & Randevu',
};

// ABOUT DATA
export const aboutData = {
  img: 'tb.jpg',
  paragraphOne:
    'Manisa Celal Bayar Üniversitesi Fizyoterapi ve Rehabilitasyon bölümünden lisans derecesiyle mezun oldum. Öğrenimim sırasında Manisa Özel Egem özel eğitim ve rehabilitasyon merkezinde, özel eğitim gören, engeli bulunan kardeşlerimizle çalıştım. 4. Sınıfta spor fizyoterapisi üzerine Sivasspor Kulübü A-takım futbol takımı sağlık ekibinde deneyim kazandım.',
  paragraphTwo:
    'Üniversiteden mezun olduktan  sonra Litvanyada bulunan yatılı Palanga Rehabilitasyon Hastanesinde 6 ay çalıştım.',
  paragraphThree:
    "Sonrasında Türkiye'ye döndüm ve evde fizik tedavi almak isteyen özel hastalara sağlık hizmeti vermekteyim.",
  resume:
    'https://wa.me/905442580900/?text=Merhaba%2C%0AFizyoterapi%20hizmeti%20almak%20istiyorum.%20Bu%20konuda%20bana%20yard%C4%B1mc%C4%B1%20olabilir%20misiniz%3F', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'img1.jpg',
    title: 'Nörolojik Hastalıklarda Fizyoterapi',
    info: [
      'Parkinson',
      'İnme (Felç)',
      'Serebral Palsi (CP)',
      'Multiple Skleroz (MS)',
      'Brachial Pleksus',
      'Spina Bifida',
    ],
    info2: [
      'Denge Bozukluğu',
      'Myopati (Kas Hastalıkları)',
      'Polinöropati (Sinirlerin işlevlerini kaybetmesi)',
      'Sinir Yaralanmaları',
      'Omurilik Yaralanmaları',
      'Fasiyal Paralizi (Yüz Felci)',
      'Ve Sinir Sistemini Etkileyen Diğer Problemler…',
    ],
    url: '/#contact',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'omr1.jpg',
    title: 'Omurga Hasarlarının Tedavisi',
    info: [
      'Postür (Duruş) Bozuklukları',
      'Boyun -Bel ve Sırt Ağrıları',
      'Boyun -Bel Fıtıkları',
      'Fibromiyalji (Kas Romatizması)',
      'Ankilozan Spondilit (Omurganın Tutulumu)',
      'Kifoz (Kamburluk)',
      'Skolyoz (Omurga Eğriliği)',
      'Omurilik Yaralanmaları',
    ],
    info2: ['Ve daha bir çok omurga probleminde...'],
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'rehab1.jpg',
    title: 'Ortopedik Rehabilitasyon',
    info: [
      'Bağ ve Tendon Problemleri ve Cerrahi Sonrası',
      'Çapraz Bağ Lezyonları',
      'Menisküs Lezyonları',
      'Aşil Tendinit',
      'Dirsek, Omuz, Kalça, Diz, El, Ayak ve Bilek Yaralanmaları ve Cerrahisi Sonrası',
      'Kıkırdak Hasarları, Yaralanmaları ve Kıkırdak Cerrahisi Sonrası',
    ],
    info2: [
      'Sinir Sıkışma Sorunları (Karpal Tünel, Tarsal Tünel Sendromu vb.)',
      'Artritler (Kireçlenmeler ve Romatizmal Hastalıklar)',
      'Kemik Erimesi (Osteoporoz)',
      'Kırık ve Kırık Cerrahisi Sonrası',
      'Eklem Protez Cerrahisi Sonrası (Kalça, Diz Protezi vb.)',
    ],
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'spor1.jpg',
    title: 'Spor Fizyoterapisi ve Sporcu Sağlığı İzleme',
    info: [
      'Sporcu Yaralanmarı / Sakatlanmalarında ve Cerrahi Sonrası Tedavilerde;',
      'Bağ ve Tendon Problemleri ve Cerrahi Sonrası',
      'Çapraz Bağ Lezyonları',
      'Menisküs Lezyonları',
      'Aşil Tendinit...',
      'Dirsek, Omuz, Kalça, Diz, El, Ayak ve Bilek Yaralanmaları ve Cerrahisi Sonrası',
      'Koruyucu Eklem Cerrahisi Sonrası (Femoroasetabular Sıkışma Sendromu, Labrum Yırtığı vb.)',
      'Kıkırdak Hasarları, Yaralanmaları ve Kıkırdak Cerrahisi Sonrası',
      'Kök Hücre Uygulamaları Sonrası',
    ],
    info2: [
      'Spora dönüş ve performansın yükseltilmesinde',
      'Yaralanmalardan Koruma Programı',
      'Spora Özgü Rehabilitasyon Programı Hazırlanması...',
    ],
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'terapi@talhabahtiyar.com',
  email: 'terapi@talhabahtiyar.com',
  whatsapp:
    'https://wa.me/905442580900/?text=Merhaba%2C%0AFizyoterapi%20hizmeti%20almak%20istiyorum.%20Bu%20konuda%20bana%20yard%C4%B1mc%C4%B1%20olabilir%20misiniz%3F',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: uuidv1(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: uuidv1(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/talha-bahtiyar',
    },
    // {
    //   id: uuidv1(),
    //   name: 'instagram',
    //   url: 'https://instagram.com/talhab0',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
