import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';
import Title from '../Title/Title';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const itemLis = items => {
    const newItems = [];
    for (let i = 0; i < items.length; i += 1) {
      newItems.push(<li key={i}> {items[i]}</li>);
    }
    return newItems;
  };
  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Evde Fİzİk Tedavİ" />
          <div className="project-wrapper__text">
            <p>
              Tedaviler, gerekli görüldüğünde Manuel Terapi, Mobilizasyon, Kinesiotaping (Bantlama),
              Elektroterapi, Masaj Teknikleri, Tetik Nokta Masajı, Cupping (Hacamat) gibi daha bir
              çok teknik ve Kişiye Özel Egzersiz Programı ile kombine olarak uygulanır.
            </p>
          </div>
          <br />
          {projects.map(project => {
            const { id, title, info, info2, url, repo, img } = project;
            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={300}
                    delay={100}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <ul>{itemLis(info)}</ul>
                        <ul>{itemLis(info2)}</ul>
                      </div>
                      <a
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#contact'}
                      >
                        İletişim
                      </a>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={300}
                    delay={600}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      {/* <a
                        href={url || ''}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      > */}
                      <Tilt
                        options={{
                          reverse: false,
                          max: 8,
                          perspective: 1000,
                          scale: 1,
                          speed: 300,
                          transition: true,
                          axis: null,
                          reset: true,
                          easing: 'cubic-bezier(.03,.98,.52,.99)',
                        }}
                      >
                        <div data-tilt className="thumbnail rounded">
                          <ProjectImg alt={title} filename={img} />
                        </div>
                      </Tilt>
                      {/* </a> */}
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
