import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import ConversionLink from '../Gtag/ConversionLink';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email, whatsapp } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="İletİşİm" />
        <Fade bottom duration={300} delay={200} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">{cta}</p>
            <ConversionLink
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : ''}
            >
              <i className="fa fa-envelope fa-lg" />

              <span> {btn}</span>
            </ConversionLink>
            <br />
            <br />
            <ConversionLink
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={whatsapp}
            >
              <i className="fa fa-whatsapp fa-lg" />
              <span> WhatsApp</span>
            </ConversionLink>

            <br />
            <br />
            <ConversionLink
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="tel:+905442580900"
            >
              <i className="fa fa-mobile-phone fa-lg" />
              <span> 0544 258 09 00</span>
            </ConversionLink>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
